<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item label="散拍价格" prop="SPJG">
            <el-input
              v-model="ruleForm.SPJG"
              autocomplete="off"
              placeholder="请输入散拍价格..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="校拍价格" prop="XPJG">
            <el-input
              v-model="ruleForm.XPJG"
              placeholder="请输入校拍价格..."
              autocomplete="off"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户须知" prop="userXZ">
            <el-input
              v-model="ruleForm.userXZ"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 10 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="预约须知" prop="subscribeXZ">
            <el-input
              v-model="ruleForm.subscribeXZ"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="重拍说明" prop="CPSM">
            <el-input
              v-model="ruleForm.CPSM"
              type="textarea"
              placeholder="请输入重拍说明..."
              :autosize="{ minRows: 6, maxRows: 10 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服电话" prop="KFDH">
            <el-input
              v-model="ruleForm.KFDH"
              type="textarea"
              placeholder="请输入客服电话..."
            ></el-input>
          </el-form-item>
          <el-form-item label="隐私协议" prop="YSXY">
            <el-input
              v-model="ruleForm.YSXY"
              type="textarea"
              placeholder="请输入隐私协议..."
              :autosize="{ minRows: 6, maxRows: 10 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认联系人" prop="centerContact">
            <el-input
              v-model="ruleForm.centerContact"
              autocomplete="off"
              placeholder="请输入默认联系人..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认联系电话" prop="centerPhone">
            <el-input
              v-model="ruleForm.centerPhone"
              autocomplete="off"
              placeholder="请输入默认联系电话..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认联系地址" prop="centerAddress">
            <el-input
              v-model="ruleForm.centerAddress"
              autocomplete="off"
              placeholder="请输入默认联系地址..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认电子邮箱" prop="centerEmail">
            <el-input
              v-model="ruleForm.centerEmail"
              autocomplete="off"
              placeholder="请输入默认电子邮箱..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="分社名称" prop="centerName">
            <el-input
              v-model="ruleForm.centerName"
              autocomplete="off"
              placeholder="请输入分社名称..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认卡号" prop="centerNo">
            <el-input
              v-model="ruleForm.centerNo"
              autocomplete="off"
              placeholder="请输入默认卡号..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付类型">
            <el-radio-group v-model="ruleForm.acquiescencepay">
              <el-radio label="1">工行支付</el-radio>
              <el-radio label="2">微信支付</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备用支付">
            <el-radio-group v-model="ruleForm.havebackup">
              <el-radio label="1">启用</el-radio>
              <el-radio label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否开启散拍">
            <el-radio-group v-model="ruleForm.isSp">
              <el-radio label="1">启用</el-radio>
              <el-radio label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否运行小程序上传采集码">
            <el-radio-group v-model="ruleForm.isUpload">
              <el-radio label="1">启用</el-radio>
              <el-radio label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否开启采集端版本验证">
            <el-radio-group v-model="ruleForm.isVersion">
              <el-radio label="1">启用</el-radio>
              <el-radio label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采集端版本号" prop="versionNo">
            <el-input
              v-model="ruleForm.versionNo"
              autocomplete="off"
              placeholder="请输入采集端版本号，（例：V2.0.0）"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="摄影师">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="showInput"
              v-model="inputValue"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
              placeholder="摄影师"
              style="width: 100px"
            >
            </el-input>
            <el-button
              class="button-new-tag"
              size="small"
              v-if="!showInput"
              @click="showInput = true"
              >添加摄影师</el-button
            >
          </el-form-item>
          <el-form-item label="默认密码接收手机号" prop="passwordMobile">
            <el-input
              v-model="ruleForm.passwordMobile"
              autocomplete="off"
              placeholder="请输入默认密码接收手机号..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认邮政编码" prop="postalCode">
            <el-input
              v-model="ruleForm.postalCode"
              autocomplete="off"
              placeholder="请输入默认邮政编码..."
              :rows="4"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>

        <div style="display: flex; justify-content: flex-end">
          <el-button
            v-if="submit"
            style="width: 131px; margin-bottom: 20px"
            type="primary"
            @click="submitForm()"
            size="small"
            >更新
          </el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { queryParam, updateParam } from "../../../api/param.js";
import { queryPageButton } from "@/api/permission";

export default {
  name: "ParamSet",
  data() {
    var res = /^[0-9]+(\.[0-9]{2})?$/;
    var validateSPJG = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入散拍价格"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确"));
        }
        callback();
      }
    };

    var validateXPJG = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入校拍价格"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确"));
        }
        callback();
      }
    };

    var validateuserXZ = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入用户说明"));
      } else {
        callback();
      }
    };

    var validatesubscribeXZ = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入预约说明"));
      } else {
        callback();
      }
    };

    var validateCPSM = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入重拍说明"));
      } else {
        callback();
      }
    };

    var validateKFDH = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入客户电话"));
      } else {
        callback();
      }
    };

    var validateYSXY = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入隐私协议"));
      } else {
        callback();
      }
    };
    var validateCenterContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认联系人"));
      } else {
        callback();
      }
    };
    var validateCenterPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认联系电话"));
      } else {
        callback();
      }
    };
    var validateCenterAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认联系地址"));
      } else {
        callback();
      }
    };
    var validateCenterEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认电子邮箱"));
      } else {
        callback();
      }
    };
    var validateCenterName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入分社名称"));
      } else {
        callback();
      }
    };
    var validateCenterNo = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认卡号"));
      } else {
        callback();
      }
    };
    var validatePasswordMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认密码接收手机号"));
      } else {
        callback();
      }
    };
    var validatePostalCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入默认邮政编码"));
      } else {
        callback();
      }
    };
    return {
      dynamicTags: [],
      showInput: false,
      inputValue: "",
      ruleForm: {
        SPJG: "",
        XPJG: "",
        userXZ: "",
        subscribeXZ: "",
        CPSM: "",
        KFDH: "",
        YSXY: "",
        centerContact: "",
        centerPhone: "",
        centerAddress: "",
        centerEmail: "",
        centerName: "",
        centerNo: "",
        passwordMobile: "",
        postalCode: "",
        acquiescencepay: "1",
        havebackup: "0",
        isSp: "0",
        isUpload: "0",
        isVersion: "1",
        versionNo: "",
      },
      dialogVisible: false,
      rules: {
        SPJG: [
          {
            validator: validateSPJG,
            trigger: "blur",
          },
        ],
        XPJG: [
          {
            validator: validateXPJG,
            trigger: "blur",
          },
        ],
        userXZ: [
          {
            validator: validateuserXZ,
            trigger: "blur",
          },
        ],
        subscribeXZ: [
          {
            validator: validatesubscribeXZ,
            trigger: "blur",
          },
        ],
        CPSM: [
          {
            validator: validateCPSM,
            trigger: "blur",
          },
        ],
        KFDH: [
          {
            validator: validateKFDH,
            trigger: "blur",
          },
        ],
        YSXY: [
          {
            validator: validateYSXY,
            trigger: "blur",
          },
        ],
        centerContact: [
          {
            validator: validateCenterContact,
            trigger: "blur",
          },
        ],
        centerPhone: [
          {
            validator: validateCenterPhone,
            trigger: "blur",
          },
        ],
        centerAddress: [
          {
            validator: validateCenterAddress,
            trigger: "blur",
          },
        ],
        centerEmail: [
          {
            validator: validateCenterEmail,
            trigger: "blur",
          },
        ],
        centerName: [
          {
            validator: validateCenterName,
            trigger: "blur",
          },
        ],
        centerNo: [
          {
            validator: validateCenterNo,
            trigger: "blur",
          },
        ],
        passwordMobile: [
          {
            validator: validatePasswordMobile,
            trigger: "blur",
          },
        ],
        postalCode: [
          {
            validator: validatePostalCode,
            trigger: "blur",
          },
        ],
      },

      submit: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 16;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "submit") {
            this.submit = true;
          }
        }
      });
    },
    query() {
      queryParam()
        .then((resp) => {
          console.log(resp);
          this.ruleForm.XPJG = resp.data.schoolAuctionPrice;
          this.ruleForm.SPJG = resp.data.scatteredAuctionPrice;
          this.ruleForm.userXZ = resp.data.userInstructions;
          this.ruleForm.subscribeXZ = resp.data.bookingInstructions;
          this.ruleForm.CPSM = resp.data.remakeDescription;
          this.ruleForm.KFDH = resp.data.kefudianhua;
          this.ruleForm.YSXY = resp.data.yinsixieyi;
          this.ruleForm.acquiescencepay = resp.data.acquiescencepay; //支付类型，1工行支付，2微信支付
          this.ruleForm.havebackup = resp.data.havebackup;
          this.ruleForm.isSp = resp.data.isSp;
          this.ruleForm.isUpload = resp.data.isUpload;
          this.ruleForm.centerContact = resp.data.centerContact;
          this.ruleForm.centerPhone = resp.data.centerPhone;
          this.ruleForm.centerAddress = resp.data.centerAddress;
          this.ruleForm.centerEmail = resp.data.centerEmail;
          this.ruleForm.centerName = resp.data.centerName;
          this.ruleForm.centerNo = resp.data.centerNo;
          this.ruleForm.passwordMobile = resp.data.passwordMobile;
          this.ruleForm.postalCode = resp.data.postalCode;
          this.ruleForm.isVersion = resp.data.isVersion ?? "1";
          this.ruleForm.versionNo = resp.data.versionNo;

          if (resp.data.photographer) {
            this.dynamicTags = resp.data.photographer.split(",");
          }
        })
        .catch((e) => {
          console.log(e.responseText);
        });
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const paramruleForm = {
            schoolAuctionPrice: this.ruleForm.XPJG,
            scatteredAuctionPrice: this.ruleForm.SPJG,
            userInstructions: this.ruleForm.userXZ,
            bookingInstructions: this.ruleForm.subscribeXZ,
            remakeDescription: this.ruleForm.CPSM,
            kefudianhua: this.ruleForm.KFDH,
            yinsixieyi: this.ruleForm.YSXY,
            acquiescencepay: this.ruleForm.acquiescencepay,
            havebackup: this.ruleForm.havebackup,
            isSp: this.ruleForm.isSp,
            isUpload: this.ruleForm.isUpload,

            centerContact: this.ruleForm.centerContact,
            centerPhone: this.ruleForm.centerPhone,
            centerAddress: this.ruleForm.centerAddress,
            centerEmail: this.ruleForm.centerEmail,
            centerName: this.ruleForm.centerName,
            centerNo: this.ruleForm.centerNo,
            passwordMobile: this.ruleForm.passwordMobile,
            postalCode: this.ruleForm.postalCode,
            isVersion: this.ruleForm.isVersion,
            versionNo: this.ruleForm.versionNo,
          };
          if (this.dynamicTags.length) {
            paramruleForm.photographer = this.dynamicTags.join(",");
          }
          updateParam(paramruleForm)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
            })
            .catch((e) => {
              console.log(e.responseText);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.showInput = false;
      this.inputValue = "";
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100vh - 145px);
  overflow-y: auto;
}
.cardBody {
  height: calc(100% - 40px);
}
.el-tag--light {
  margin-right: 5px;
}
</style>
